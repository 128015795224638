import { Menu } from "@headlessui/react";
import Link from "next/link";
import { services, solutions, resources } from "./links";

function MobileMenuSubmenuLink({ link }) {
  return (
    <Menu.Item as="div" className="">
      {({ active }) => (
        <a
          className={"group flex items-center w-full px-2 py-3 ml-4 text-sm hover:text-gray-500 " + (active ? "text-gray-100" : "text-gray-300")}
          href={link.href}
        >
          {link.name}
        </a>
      )}
    </Menu.Item>
  );
}

function MobileMenuLink({ link }) {
  return (
    <Menu.Item as="div">
      {({ active }) => (
        <a
          className={ "group flex items-center w-full py-3 ml-4 text-md font-bold " + (active ? "text-gray-500" : "text-white")}
          href={link.href}
        >
          {link.name}
        </a>
      )}
    </Menu.Item>
  );
}

function MobileMenuHeader({ header }) {
  return (
    <div>
      <span className="group flex items-center w-full ml-4 py-3 text-md text-white font-bold">
        {header.name}
      </span>
    </div>
  );
}

function MobileMenu() {
  return (
    <Menu as="div" className="md:hidden">
      <Menu.Button aria-label="Open Menu">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </span>
      </Menu.Button>
      <Menu.Items as="div"
        className="absolute bg-gray-900 mt-5 -ml-1 -mr-1 origin-top-right bg-gray-900 shadow-xl left-px right-px px-2 pt-5 pb-8
        border-solid border-0 border-y border-slate-300"
      >
        <div className="h-[65vh] overflow-y-scroll">
          <MobileMenuLink link={{ href: "/", name: "Home" }} />
          <MobileMenuHeader header={{ name: "Solutions" }} />
          {solutions.map((link) => {
            return <MobileMenuSubmenuLink key={link.name} link={link} />;
          })}
          <MobileMenuHeader header={{ name: "Resources" }} />
          {resources.map((link) => {
            return <MobileMenuSubmenuLink key={link.name} link={link} />;
          })}
          <MobileMenuLink link={{ href: "/pricing", name: "Pricing" }} />
        </div>
        <div>
          <Menu.Item as="div">
            <Link href="/beta-signup">
              <a className="btn bg-green-400 content-center flex flex-col items-center ml-5 text-gray-900 mr-5 mt-4 rounded-full">
                Sign up
              </a>
            </Link>
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}

export default MobileMenu;
