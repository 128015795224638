import Link from "next/link";
import Logo from "../logo"

export default function Title() {
  return (
    <Link href="/" passHref>
      <h1 className="text-center text-neutral-content text-2xl md:text-2xl md:text-center md:2-32 cursor-pointer">
        Backbrain
      </h1>
    </Link>
  );
}
