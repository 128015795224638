import Link from "next/link";
import Logo from "../logo";
import Title from "../objects/title";
import HeaderMiddleSection from "./headerMiddleSection";
import MobileMenu from "./mobileMenu";

const HeaderSection = () => {
  return (
    <section className="z-50 fixed w-full shadow-blur-sm shadow bg-gray-900/20 text-neutral-content backdrop-blur-lg border-solid border-0 border-b border-slate-300/50 shadow-2xl shadow-gray-800/40 mix-blend-normal">
      <div className="container max-w-screen-7xl 2xl:max-w-screen-2xl flex items-center justify-between mx-auto overflow-hidden font-medium h-20 px-4 md:overflow-visible md:justify-between md:justify-between">
        <div>
          <Title/> 
        </div>
        <div className="items-start justify-center w-full space-x-6 text-center text-sm antialiased hidden md:ml-20 md:flex">
          <HeaderMiddleSection />
        </div>
        <div className="relative group">
          <div className="items-start justify-end gap-3 hidden md:items-center md:flex-row md:py-0 md:flex sm:ml-5">
              <div className="items-start justify-end gap-3 items-center md:flex-row md:py-0 md:flex">
                <div className="absolute -inset-0.5 -ml-0.5 bg-gradient-to-br from-pink-500 to-orange-400 rounded-full blur-sm opacity-0 group-hover:opacity-75 transition duration-1000 group-hover:duration-700 animation-tilt"></div>
                <Link href="/beta-signup" passHref>
                  <span className="btn text-white bg-gray-800/80 hover:bg-gray-800/80 border-gray-800 px-10 rounded-full hover:border-transparent rounded-full leading-none flex items-center divide-x divide-gray-600 drop-shadow-xl normal-case">
                    Sign up
                  </span>
                </Link>
              </div>
            </div>
        </div>
        <div className="flex md:hidden items-center justify-start h-full pr-4">
          <MobileMenu />
        </div>
      </div>
    </section>
  );
};
export default HeaderSection;
