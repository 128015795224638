import Link from "next/link";
import React, { useState } from "react";
import axios from "axios";

export const NewsLetter = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // prevent form submit default behavior

    if (!firstName || !lastName || !email) return; // if an input field is empty, don't submit the form

    window.document
      .getElementById("newsletter-btn-spinner")
      .classList.remove("hidden");

    const hubspot_response = await submit_hubspot_form(
      firstName,
      lastName,
      email
    );
    setFirstName("");
    setLastName("");
    setEmail("");
    window.document
      .getElementById("newsletter-btn-spinner")
      .classList.add("hidden");
    console.log(hubspot_response); // make sure it succeeded!
  };

  const submit_hubspot_form = async (firstName, lastName, email) => {
    const portalId = "25780819"; // example portal ID (not real)
    const formGuid = "df936696-a07b-482d-a8c5-238ccaa63cca"; // example form GUID (not real)
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `https://api-eu1.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: "firstname",
            value: firstName,
          },
          {
            name: "lastname",
            value: lastName,
          },
          {
            name: "email",
            value: email,
          },
        ],
      },
      config
    );
    return response;
  };

  return (
    <div className="bg-gray-900 border-solid border-0 border-y border-slate-300/10 pb-3 pt-1">
      <div className="py-6">
        <div className="px-4 mx-auto max-w-screen-7xl 2xl:max-w-screen-2xl md:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 items-center">
            <div className="text-center text-neutral md:text-left mb-3 md:mb-0">
              <span className="font-bold normal-case tracking-widest text-white">
                Newsletter
              </span>
              <p className="text-gray-300 antialiased">Subscribe to our newsletter</p>
            </div>
            <form
              onSubmit={handleSubmit}
              className="w-full grid sm:col-span-2 md:grid-cols-5 gap-4"
              autoComplete="on"
            >
              <div className="">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-400 ml-3 antialiased"
                >
                  Firstname
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="w-full flex-1 h-12 input rounded-full"
                    placeholder="John"
                    autoComplete="given-name"
                  />
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-400 ml-3 antialiased"
                >
                  Lastname
                </label>
                <div className="mt-1">
                  <input
                    type="lastName"
                    name="lastName"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="w-full flex-1 h-12 input input rounded-full"
                    placeholder="Doe"
                    autoComplete="family-name"
                  />
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-400 ml-3 antialiased"
                >
                  Email
                </label>
                <div className="mt-1 ">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full flex-1 h-12 input input rounded-full"
                    placeholder="john.doe@mail.com"
                  />
                </div>
              </div>
              <Link href="/beta-signup" passHref>
                <a href="#" className="btn text-gray-900 bg-green-400 hover:bg-green-300 mt-6 rounded-full normal-case">
                  Join Newsletter
                </a>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
