import Link from "next/link";

export default function HeaderLink(props) {
  return (
    <Link
      href={props.href}
      passHref
      // locale="en"
    >
      <a className="group rounded-md inline-flex items-center text-base font-medium text-neutral-content hover:text-gray-300 focus:outline-none">
        {props.children}
      </a>
    </Link>
  );
}
