import { AiOutlineSlack, AiOutlineLinkedin } from "react-icons/ai";
import Link from "next/link";
import Logo from "../logo";

const redirect = "/static/oops"

const FooterSection = () => {
  return (
    <div className="bg-gray-900">
      <footer className="max-w-screen-2xl px-4 md:px-8 mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-12 lg:gap-8 pt-10 lg:pt-12 mb-16">
          <div className="col-span-full lg:col-span-2">
            <div className="lg:-mt-2 mb-4">
              <Link passHref href="#">
                <a className="inline-flex items-center text-gray-100 text-xl md:text-2xl font-bold gap-2">
                  <span>
                    <Logo/>
                  </span>
                  <span>Backbrain</span>
                </a>
              </Link>
            </div>
            <p className="flex text-left -mt-3 text-sm font-thin text-gray-400 antialiased mb-8">
              Join our communitiy to ask questions and join us in building Backbrain!
            </p>
            <div className="flex gap-4">
              <Link
                passHref
                href="https://join.slack.com/t/backbrain-community/shared_invite/zt-1796y45n9-TR_4LbGobYTOKw6UCifeQA"
              >
                <a
                  className="text-gray-400 hover:text-gray-500 hover:scale-125 active:text-gray-500 transition duration-100"
                  rel="nofollow"
                  aria-label="Link to our slack community"
                >
                  <AiOutlineSlack className={"w-10 h-10"} />
                </a>
              </Link>
              <Link passHref href="https://www.linkedin.com/company/backbrain/">
                <a
                  aria-label="Link our linkedin page"
                  className="text-gray-400 hover:text-gray-500 hover:scale-125 active:text-gray-500 transition duration-100"
                >
                  <AiOutlineLinkedin className={"w-10 h-10"} />
                </a>
              </Link>
            </div>
          </div>
          <div>
            <div className="text-gray-100 tracking-widest mb-4 flex text-base text-left font-medium text-white">
              Product
            </div>
            <nav className="flex flex-col gap-4">
              <div>
                <Link passHref href="/solutions/workspace-identity">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Worspace Identity
                  </a>
                </Link>
              </div>
              <div>
                <Link href="/solutions/user-identity">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    User Identity
                  </a>
                </Link>
              </div>
              <div>
                <Link passHref href="/solutions/self-service">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Self-Service
                  </a>
                </Link>
              </div>
              <div>
                <Link passHref href="/solutions/application-management">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Application Administration
                  </a>
                </Link>
              </div>
            </nav>
          </div>
          <div>
            <div className="text-gray-100 tracking-widest mb-4 flex text-base text-left font-medium text-white">
              Support
            </div>
            <nav className="flex flex-col gap-4">
              <div>
                <Link passHref href={redirect}>
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Contact Us
                  </a>
                </Link>
              </div>
            </nav>
          </div>
          <div>
            <div className="text-gray-100 tracking-widest mb-4 flex text-base text-left font-medium text-white">
              Legal
            </div>
            <nav className="flex flex-col gap-4">
              <div>
                <Link passHref href="/legal/terms-of-service">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Terms of Service
                  </a>
                </Link>
              </div>
              <div>
                <Link passHref href="/legal/privacy-policy">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Privacy Policy
                  </a>
                </Link>
              </div>
              <div>
                <Link passHref href="/legal/imprint">
                  <a className="text-gray-400 hover:text-white transition duration-100 antialiased">
                    Imprint
                  </a>
                </Link>
              </div>
            </nav>
          </div>
        </div>
        <div className="text-gray-400 text-sm text-center border-t border-gray-800 py-8">
          © 2023 - All rights reserved - BackBrain GmbH DE344902222
        </div>
      </footer>
    </div>
  );
};

export default FooterSection;
