import Head from "next/head";
import Script from "next/script";

import FooterSection from "./footer/footerSection";
import HeaderSection from "./header/headerSection";
import NewsLetter from "./newsletter";

export default function Layout({ children }) {
  return (
    <div className="bg-gradient-to-br from-[#020c17] to-[#122438] select-none lg:select-text">
      <Head>
        <title>Backbrain</title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <HeaderSection/>
      <div className="mx-auto pt-8 xl:pt-16 ">
        <main className="">{children}</main>
      </div>
      <NewsLetter />
      <FooterSection />
      <Script
        strategy="beforeInteractive"
        id="piwik"
        src="/js/piwik.js"
        type="text/javascript"
      ></Script>
    </div>
  );
}
