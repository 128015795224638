import { Popover } from "@headlessui/react";
import HeaderLink from "./headerLink";
import HeaderSubmenuLink from "./headerSubmenuLink";
import HeaderSubmenuLinkPlain from "./headerSubmenuLinkPlain";
import { services, solutions, resources, pricing } from "./links";

export function HeaderMiddleSection() {
  return (
    <Popover.Group as="nav" className="hidden md:flex space-x-10">
      <HeaderLink href="/">Home</HeaderLink>
      {/* <HeaderSubmenuLink data={services}>
        <span>Services</span>
      </HeaderSubmenuLink> */}
      <HeaderSubmenuLinkPlain data={solutions}>
        <span>Product</span>
      </HeaderSubmenuLinkPlain>
      <HeaderSubmenuLinkPlain data={resources}>
        <span>Resources</span>
      </HeaderSubmenuLinkPlain>
      {/*<HeaderLink href={pricing.href}>Pricing</HeaderLink> */}
      {/* <HeaderLink href="/">Docs</HeaderLink> */}
    </Popover.Group>
  );
}

export default HeaderMiddleSection;
