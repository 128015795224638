import {
  UserCircleIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  ChipIcon,
  SwitchHorizontalIcon,
  UserGroupIcon,
  ShoppingCartIcon,
  ShieldExclamationIcon,
  EmojiHappyIcon,
  DocumentTextIcon,
} from "@heroicons/react/outline";

const services = [
  // {
  //   name: "The BackBrain Platform",
  //   description:
  //     "Your control panel to customize your application and manage your customers.",
  //   href: "/services",
  //   icon: ChipIcon,
  // },
  // {
  //   name: "Cortex",
  //   description:
  //     "Your control panel to customize your application and manage your customers.",
  //   href: "/services/cortex",
  //   icon: ChipIcon,
  // },
  // {
  //   name: "Identity Hub",
  //   description:
  //     "The self-service your customers are using to manage their accounts.",
  //   href: "/services/identity-hub",
  //   icon: UserCircleIcon,
  // },
];

const resources = [
  {
    name: "Documentation",
    description: "Open our official Documentation for the beta access.",
    href: "https://backbrain.stoplight.io/docs/docs/",
    icon: UserGroupIcon,
  }
];

const solutions = [
  {
    name: "Workspace Identity",
    description:
      "Learn how Backbrain can help effortlessly unify your digital workspace.",
    href: "/solutions/workspace-identity",
    icon: UserGroupIcon,
  },
  {
    name: "User Identity",
    description:
      "Master user identity with Backbrain's streamlined, customisable, and powerful user management.",
    href: "/solutions/user-identity",
    icon: UserGroupIcon,
  },
  {
    name: "Self Service",
    description:
      "Learn how your users can manage themselves with ease.",
    href: "/solutions/self-service",
    icon: UserGroupIcon,
  },
  {
    name: "Application Administration",
    description:
      "Administrate your customer's users, and workspaces and configure how your application works in different contexts.",
    href: "/solutions/application-management",
    icon: UserGroupIcon,
  }
];

const pricing =
  {
    name: "Pricing",
    description:
      "Learn how our multi tenant customer identity solution kickstarts your user onboarding.",
    href: "/static/oops",
    icon: UserGroupIcon,
  };

export { solutions, services, resources, pricing};
