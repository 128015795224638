import Image from "next/image";
import Link from "next/link";

export default function Logo() {
  return (
    <Link href="/" passHref>
      <a className="overflow-y-scroll">
        <Image
          className="h-10 w-auto aspect-auto"
          src="/img/logos/backbrain/BackBrain-09.svg"
          alt="Logo Backbrain"
          width="70px"
          height="70px"
        />
      </a>
    </Link>
  );
}