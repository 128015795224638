import Link from "next/link";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderSubmenuLinkPlain(props) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-neutral-content" : "text-neutral-content",
              "link-neutral-content group rounded-md inline-flex items-center text-base font-medium hover:text-gray-300 focus:outline-none"
            )}
          >
            {props.children}
            <ChevronDownIcon
              className={classNames(
                open ? "text-neutral-content" : "text-neutral-content",
                "ml-2 h-5 w-5 group-hover:text-gray-300"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="rounded-lg bg-gray-800 shadow-2xl ring-1 ring-gray-600 ring-opacity-5 absolute z-10 -ml-4 mt-4 transform w-screen mx-auto max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
              <div className="relative grid items-start gap-6 px-5 py-6 sm:gap-8 sm:p-8">
                {props.data.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex -m-3 p-3 rounded-lg hover:bg-gray-700 "
                  >
                    <div className="ml-4">
                      <p className="flex text-base text-left font-medium">
                        {item.name}
                      </p>
                      <p className="flex text-left mt-1 text-sm font-thin text-gray-400 antialiased">
                        {item.description}
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
